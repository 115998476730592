'use strict';(function alertCollapseJs() {
  function hasCookieConsent() {
    if (!window.OnetrustActiveGroups) return true;
    return window.OnetrustActiveGroups.includes('C0003');
  }

  function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : false;
  }

  function setCookie(cname, cvalue, milliseconds) {
    if (!hasCookieConsent()) return;
    var d = new Date();
    d.setTime(d.getTime() + milliseconds);
    document.cookie = cname + '=' + cvalue + ';expires=' + d.toUTCString() + ';path=/';
  }

  function initAlertListeners(elAlertBanner) {
    var alertId = elAlertBanner.dataset.alertId;

    if (getCookie(alertId)) {
      elAlertBanner.classList.add('u-display--none');
      return;
    }

    var btnAlertClose = elAlertBanner.querySelector('.js-alert-close');
    var alertExpiration = Number(elAlertBanner.dataset.expiration);

    btnAlertClose.addEventListener('click', function (e) {
      e.preventDefault();
      elAlertBanner.classList.add('u-display--none');
      setCookie(alertId, 'clicked', alertExpiration);
    });
  }

  var arrAlertBanner = document.querySelectorAll('.js-alert-collapse');
  for (var i = 0; i < arrAlertBanner.length; i++) {
    initAlertListeners(arrAlertBanner[i]);
  }
  //This code is also being used for the persistent CTA
})();