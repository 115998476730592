'use strict';(function (w, d, $) {

  $(d).ready(function () {
    var nav = $('nav.pagination--block');
    var pages = $('nav.pagination--block > ul li');
    var first = $('.pagination--block ul > li:first');
    var last = $('.pagination--block ul > li:last');
    var curPg = nav.attr('data-currentpage');
    var curLi = getLi(curPg);
    //console.log(`curLi ${curLi}`);
    var totalPg = nav.attr('data-totalpages');
    // console.log('total ', totalPg);
    var priorPg = parseFloat(curPg) - 1; //PREV
    // console.log(`priorPg ${priorPg}`);
    var prevLi = getLi(priorPg); //PREV
    // console.log(`prevLi ${prevLi}`);
    var nextPg = parseFloat(curPg) + 1;
    var nextLi = getLi(nextPg); // NEXT
    // console.log(`nextLi ${nextLi}`);
    var isFirst = curPg === '1';
    var isLast = curPg === totalPg;
    var totLi = getTotalLi(pages.length);

    makeActive(curPg);
    firstLast();
    applyBefEllipse(prevLi);
    applyAftEllipse(nextLi);

    function getLi(num) {
      return parseFloat($(pages[num]).find('a').text());
    }

    function getTotalLi(plength) {
      var tally = plength;
      tally = !isFirst ? tally - 1 : tally;
      tally = !isLast ? tally - 1 : tally;
      return tally;
    }

    function applyBefEllipse(nde) {
      // console.log(`before current: ${nde}`);
      var firstLi = getLi(1);
      // console.log('firstli ', firstLi);
      var secLi = getLi(2);
      // console.log('secLi ', secLi);
      var thirdLi = getLi(3);
      if (nde != firstLi && nde != secLi && nde != thirdLi && !isFirst) {
        $(pages[nde]).addClass('pagination--ellipsis');
        $(pages[nde]).find('a').html('&hellip;');
        $(pages).each(function (indx) {
          if (indx !== 0 && indx < prevLi) {
            if (indx !== firstLi && indx !== secLi && indx !== thirdLi) {
              $(pages[indx]).css('display', 'none');
            }
          }
        });
      }
    }

    function applyAftEllipse(nde) {
      // console.log('After current: ', nde);
      var lastLi = totLi;
      var secLaLi = totLi - 1;
      var thirdLaLi = totLi - 2;
      //console.log('last, sec, third: ', lastLi, secLaLi, thirdLaLi);
      if (nde != lastLi && nde != secLaLi && nde != thirdLaLi) {
        //console.log(`apply after ${nde}`);
        $(pages[nde]).addClass('pagination--ellipsis');
        $(pages[nde]).find('a').html('&hellip;');
        $(pages).each(function (indx) {
          if (indx !== 0 && indx !== totalPg && indx > nde && indx < totalPg) {
            if (indx !== lastLi && indx !== secLaLi && indx !== thirdLaLi) {
              $(pages[indx]).css('display', 'none');
            }
          }
        });
      }
    }

    function makeActive(cur) {
      if (pages.hasClass('active')) {
        pages.removeClass('active');
      }
      $(pages[cur]).addClass('active');
    }

    function firstLast() {
      if (isFirst) {
        first.css('display', 'none');
      }

      if (isLast) {
        last.css('display', 'none');
      }

    }

  });

  function nodeExists(nde) {
    return nde.length > 0;
  }

  function resizePagination() {
    var first = $('.pagination--block ul > li:first').width();
    var last = $('.pagination--block ul > li:last').width();
    if (first > last) {
      $('.pagination--block ul > li:last').width(first + 'px');
    } else
    {
      $('.pagination--block ul > li:first').width(last + 'px');
    }
  }

  $(window).bind('resize', resizePagination);

  $(window).on('load', function () {
    if (nodeExists($('.pagination--block'))) {
      resizePagination();
    }
  });

})(window, document, jQuery);