'use strict';(function (w, d, $) {
	$(d).ready(function () {

		var sliderOptions = {
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1 };

		var pathPrefix = window.location.hostname === 'localhost' ? '' : '/assets/ghp';
		if ($('.photo-slideshow').length > 0) {
			$('.photo-slideshow').each(function setOptionImage() {
				if (this.classList.contains('photo-slideshow--quote-slide')) {
					sliderOptions.nextArrow = '<img class="slick-next-custom" src="' + pathPrefix + '/images/slideshow-next-transparent.png" alt="Next" />',
					sliderOptions.prevArrow = '<img class="slick-prev-custom" src="' + pathPrefix + '/images/slideshow-prev-transparent.png" alt="Previous" />';
				} else {
					sliderOptions.nextArrow = '<img class="slick-next-custom" src="' + pathPrefix + '/images/slideshow-next.png" alt="Next" />',
					sliderOptions.prevArrow = '<img class="slick-prev-custom" src="' + pathPrefix + '/images/slideshow-prev.png" alt="Previous" />';
				}
				$(this).slick(sliderOptions);
			});
		}
		if ($('.split-list').length > 0) {
			$('.split-list').each(function (indx, el) {
				splitList($(el), $(el).data('cols'));
			});
			//splitList($('.split-list').data('cols'));
		}
	});
	function splitList(el, cols) {
		var num_cols = cols,
		//container = $('.split-list'),
		container = el,
		listItem = 'li',
		listClass = 'sub-list',
		listClass2 = 'cols' + num_cols;
		container.each(function () {
			var items_per_col = [],
			items = $(this).find(listItem),
			min_items_per_col = Math.floor(items.length / num_cols),
			difference = items.length - min_items_per_col * num_cols;
			for (var i = 0; i < num_cols; i++) {
				if (i < difference) {
					items_per_col[i] = min_items_per_col + 1;
				} else {
					items_per_col[i] = min_items_per_col;
				}
			}
			for (var i = 0; i < num_cols; i++) {
				$(this).append($('<ul></ul>').addClass(listClass).addClass(listClass2));
				for (var j = 0; j < items_per_col[i]; j++) {
					var pointer = 0;
					for (var k = 0; k < i; k++) {
						pointer += items_per_col[k];
					}
					$(this).find('.' + listClass).last().append(items[j + pointer]);
				}
			}
		});
		container.contents().unwrap().wrapAll('<div class="split-list"></div>');
	}
})(window, document, jQuery);