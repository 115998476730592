'use strict';(function (w, d, $) {
  if ($('.tabbed-carousel').length > 0) {
    $('.carousel-nav').on('init', function (event, slick) {
      var nSlide = $('.slick-slide.slick-current.slick-active').data('slick-index') + 1;
      var nTitle = $(slick.$slides.get(nSlide)).data('title');
      $('.slick-manual-next strong').html(nTitle);
    });
    // slick carousel
    $('.carousel-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      infinite: false,
      adaptiveHeight: true,
      asNavFor: '.carousel-nav' });


    $('.page-section--full .carousel-nav').slick({
      slidesToShow: 8,
      slidesToScroll: 1,
      asNavFor: '.carousel-for',
      dots: false,
      infinite: false,
      focusOnSelect: true,
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6 } },


      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4 } },


      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2 }


        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      }] });

    $('.page-layout--left-col .carousel-nav').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.carousel-for',
      dots: false,
      infinite: false,
      focusOnSelect: true,
      responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4 } },


      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2 }


        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      }] });

    $('.carousel-nav').on('afterChange', function (event, slick, currentSlide, slideCount) {
      var count = $('.carousel-nav').slick('getSlick').slideCount - 1;
      if (currentSlide == count) {
        $('.slick-manual-next').hide();
      } else {
        var nextTitle = $(slick.$slides.get(currentSlide + 1)).data('title');
        $('.slick-manual-next').show();
        $('.slick-manual-next strong').html(nextTitle);
      }
    });

    $('.slick-manual-next').click(function (e) {
      e.preventDefault();
      $('.carousel-nav').slick('slickNext');
      var aTag = $('.tabbed-carousel');
      $('html,body').animate({ scrollTop: aTag.offset().top }, 'slow');
    });
  }
})(window, document, jQuery);