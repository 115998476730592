'use strict';(function searchRedirectJs() {
  var elCheckbox = document.getElementById('js-search-redirect');
  var elSearchInput = document.getElementById('cts-search-box');

  function submitHandler(e) {
    e.preventDefault();
    if (!elCheckbox.checked) {
      e.target.submit();
      return;
    }
    var urlSearchLocation = elCheckbox.dataset.searchLocation;
    var newUrl = window.location.protocol + '//' + window.location.host + urlSearchLocation + '?q=' + elSearchInput.value;
    window.location.href = newUrl;
  }

  if (elCheckbox) {
    var elSearchForm = elCheckbox.form;
    elSearchForm.addEventListener('submit', submitHandler);
  }
})();