'use strict';var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var RPUtil = function () {function RPUtil() {_classCallCheck(this, RPUtil);}_createClass(RPUtil, null, [{ key: 'isNullOrEmpty', value: function isNullOrEmpty(
    tmp) {
      return !tmp || 0 === tmp.length;
    } }, { key: 'isUndefined', value: function isUndefined(

    tmp) {
      return typeof tmp === 'undefined';
    } }, { key: 'getFromStore', value: function getFromStore(

    name) {
      if (typeof Storage !== 'undefined') {
        return sessionStorage.getItem(name);
      }
    }

    // Parameter obj is a name/value object
  }, { key: 'writeToStore', value: function writeToStore(obj) {
      if (typeof Storage !== 'undefined') {
        sessionStorage.setItem(obj.name, obj.value);
      }
    }

    // Find closest parent selector for referenced element
  }, { key: 'closest', value: function closest(refEl, findSelector) {
      if (refEl.closest) {
        return refEl.closest(findSelector);
      }

      var matches = Element.prototype.matches ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector,
      currentEl = refEl;

      while (currentEl) {
        if (matches.call(currentEl, findSelector)) {
          return currentEl;
        }
        currentEl = currentEl.parentElement;
      }
      return null;
    }

    /**
      * Get the largest value from an array
      * @param narray integer
      **/ }, { key: 'getMaxFromArray', value: function getMaxFromArray(
    narray) {
      return Math.max.apply(null, narray);
    } }, { key: 'isIE', value: function isIE()

    {
      if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
        return true;
      } else
      {
        return false;
      }
    } }, { key: 'viewportWidth', value: function viewportWidth()

    {
      var e = window;
      var a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }
      return e[a + 'Width'];
    } }, { key: 'hasClass', value: function hasClass(

    elem, tstClass) {
      return elem.className.indexOf(tstClass) !== -1;
    } }, { key: 'loaded', value: function loaded(

    document, fn) {
      if (document.readyState !== 'loading') {
        fn();
      } else
      {
        document.addEventListener('DOMContentLoaded', fn);
      }
    } }, { key: 'resize', value: function resize(

    window, fn) {
      window.addEventListener('resize', fn);
    } }]);return RPUtil;}();