'use strict';var CtaModal = function ctaModal() {
  var eModal, eStickyCtaContent;
  function closeBtnModalListener(e) {
    closeModal();
  }

  function closeOutsideModalListener(e) {
    if (e.target.classList.contains('c-modal')) {
      closeModal();
    }
  }

  function closeModal() {
    eModal.classList.add('c-modal--hidden');
    document.body.classList.remove('modal-active');
    eStickyCtaContent.classList.remove('c-cta__sticky--hidden');
  }

  function stickyCtaButtonListener(e) {
    eModal.classList.remove('c-modal--hidden');
    document.body.classList.add('modal-active');
    eStickyCtaContent.classList.add('c-cta__sticky--hidden');
  }

  function Init() {
    var stickyCta = document.querySelector('.c-cta-sticky');
    eModal = stickyCta.querySelector('.c-modal');
    var eModalContentHolder = eModal.querySelector('.c-modal__cta-holder');
    eStickyCtaContent = stickyCta.querySelector('.c-cta__sticky');
    var stickyCtaButton = eStickyCtaContent.querySelector('.c-cta__button');
    var modalContent = stickyCta.querySelector('.c-cta__content');

    eModalContentHolder.innerHTML = modalContent.innerHTML;

    stickyCtaButton.addEventListener('click', stickyCtaButtonListener);
    eModal.addEventListener('click', closeOutsideModalListener);

    var eModalClose = eModal.querySelector('.c-modal--close');
    eModalClose.addEventListener('click', closeBtnModalListener);

    window.addEventListener('keydown',
    function (e) {
      if (e.keyCode !== 27) return;
      closeModal();
    });
  }

  Init();
};