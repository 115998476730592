'use strict';var WffmModal = function () {
  function hasCookieConsent() {
    if (!window.OnetrustActiveGroups) return true;
    return window.OnetrustActiveGroups.includes('C0003');
  }

  function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : false;
  }

  function setCookie(cname, cvalue, milliseconds) {
    if (!hasCookieConsent()) return;
    if (!cname && !milliseconds) return;
    var d = new Date();
    d.setTime(d.getTime() + milliseconds);
    document.cookie = cname + '=' + cvalue + ';expires=' + d.toUTCString() + ';path=/';
  }

  function closeBtnModalListener(e) {
    closeModal(e.target.getAttribute('data-modal'));
  }

  function closeOutsideModalListener(e) {
    if (e.target.classList.contains('c-modal')) {
      closeModal(e.target.id);
    }
  }

  function checkCookie(elModalWithExpiration) {
    var modalExpireId = elModalWithExpiration.dataset.modalExpireId;
    if (!modalExpireId) return false;
    var cookie = getCookie(modalExpireId);

    if (cookie) {
      elModalWithExpiration.classList.add('c-modal--hidden');
      document.body.classList.remove('modal-active');
      return true;
    }
    return false;
  }

  function closeModal(targetId) {
    var eModal = document.getElementById(targetId);

    if (eModal.classList.contains('c-modal--experience')) return;

    eModal.classList.add('c-modal--hidden');
    document.body.classList.remove('modal-active');

    function initModalListeners(elModalExpire) {

      checkCookie(elModalExpire);
      var modalExpireId = elModalExpire.dataset.modalExpireId;
      var modalExpiration = Number(elModalExpire.dataset.expiration);

      setCookie(modalExpireId, 'clicked', modalExpiration);
    }

    var arrModalExpire = document.querySelectorAll('.js-modal-expire');
    for (var i = 0; i < arrModalExpire.length; i++) {
      initModalListeners(arrModalExpire[i]);
    }
  }

  function createModal(e) {
    e.preventDefault();
    var eModal = document.getElementById(e.target.getAttribute('data-modal'));
    if (eModal.classList.contains('c-modal--experience')) return;

    var dynamicModalContent = e.target.getAttribute('data-modal-content');
    var targetDiv = eModal.querySelector('.c-modal__wffm-holder');
    if (dynamicModalContent && targetDiv) {
      if (!targetDiv) return;
      targetDiv.innerHTML = dynamicModalContent;
    }

    eModal.classList.remove('c-modal--hidden');
    document.body.classList.add('modal-active');
  }

  function InitOpenModalButton(modalId) {
    var btnsModal = document.getElementsByClassName(modalId);

    if (btnsModal.length == 0) {
      console.log('no matching modal id on button');
      return;
    }

    btnsModal[0].setAttribute('data-modal', modalId);
    btnsModal[0].addEventListener('click', createModal);
  }

  function InitOpenModalLinkButton() {
    var linkBtnsModal = document.querySelectorAll('.js-dynamic-text-modal');

    for (var i = 0; i < linkBtnsModal.length; i++) {
      linkBtnsModal[i].addEventListener('click', createModal);
    }
  }

  function InitCloseModal(eModal, modalId) {

    eModal.addEventListener('click', closeOutsideModalListener);

    var eModalClose = eModal.querySelector('.c-modal--close');
    eModalClose.setAttribute('data-modal', modalId);
    eModalClose.addEventListener('click', closeBtnModalListener);

    window.addEventListener('keydown',
    function (e) {
      if (e.key !== 'Escape') return;
      closeModal(modalId);
    });
  }

  function InitAutoModal(eModal) {
    var isAuto = eModal.classList.contains('js-modal-auto');
    var shouldOpen = !checkCookie(eModal);


    if (eModal && isAuto && shouldOpen) {var
      autoShowModal = function autoShowModal() {
        eModal.classList.remove('c-modal--hidden');
        document.body.classList.add('modal-active');
      };
      var delayDuration = eModal.dataset.durationId;
      if (delayDuration) {
        setTimeout(autoShowModal, delayDuration);
      }
    }
  }

  function Init(modalId) {
    var eModal = document.getElementById(modalId);
    if (!eModal) return;
    InitOpenModalButton(modalId);
    InitOpenModalLinkButton();
    InitCloseModal(eModal, modalId);
    InitAutoModal(eModal);
  }

  return {
    Init: Init };

}();