'use strict';var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var CtaControl = function () {function CtaControl() {_classCallCheck(this, CtaControl);}_createClass(CtaControl, null, [{ key: 'resizeCTABlocks',
		/**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         * Resize vertically a nodelist to be the size of the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         * largest node element.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         * @param selector String
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         **/value: function resizeCTABlocks(
		selector) {
			CtaControl.resetCTA(selector);
			var blkAr = [];
			var sec = $('[class^="page-section"]');
			if (window.innerWidth > 990) {
				sec.each(function (i, val) {
					// val is page section
					$(val).find(selector).each(function (i, val2) {
						blkAr.push($(val2).height());
					});
					$(val).find(selector).height(RPUtil.getMaxFromArray(blkAr));
					blkAr = [];
				});
			} else
			{
				sec.each(function (i, val) {
					if ($(val).find(selector).length > 0) {
						$(val).find(selector).css('height', 'auto');
					}
				});
			}
		} }, { key: 'resetCTA', value: function resetCTA(

		selector) {
			var sec = $('[class^="page-section"]');
			sec.each(function (i, val) {
				if ($(val).find(selector).length > 0) {
					$(val).find(selector).css('height', 'auto');
				}
			});
		} }]);return CtaControl;}();